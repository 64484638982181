<template>
  <CRow>
    
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>User</strong>
        </CCardHeader>
        <CCardBody>
          <CForm @submit.prevent="newUser" id="form">
            <CRow>
              <CCol col=12>
                <CAlert
                  :show.sync="dismissCountDown"
                  closeButton
                  color="danger"
                  fade
                >
                  {{errorMsg}}
                </CAlert>
              </CCol>
              <CCol col=6>
                <CInput
                  label="User Name"
                  :value="this.username"
                  v-model="username"
                  bottom
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="E-mail"
                  :value="this.email"
                  v-model="email"
                  bottom
                  type="email"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Password"
                  v-model="password"
                  bottom
                  type="password"
                  required
                />
              </CCol>
              

              <CCol col=6>
                <div role="group" class="form-group">
                  <label for="_safe_id_i81sh75grp" class=""> Role </label>
                  <select name="LeaveType" v-model="roles" class="form-control">
                      <option value="">Select role</option>
                      <option value="admin">Admin</option>
                      <option value="moderator">Moderator</option>
                  </select>
                </div>

                
              </CCol>
              
              <CCol col=12 class="text-center">
                <CButton size="sm" color="warning" @click="goBack()" style="margin-right: 10px;">Back</CButton>
                <CButton type="submit" size="sm" color="success">Save</CButton>
              </CCol>
            </CRow>
            
              
          </CForm>
        </CCardBody>
        
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Users from "@/services/UserDataService";
export default {
  name: 'User',
  data: () => {
    return {
      item: '',
      id: 0,
      dismissCountDown: 0,
      errorMsg: '',
      username: '',
      email: '',
      password: '',
      roles: ''
    }
  },
  methods: {
    
    goBack() {
      this.$router.push('/users');
    },
    
    newUser() {
       Users.newUser(this.username, this.email, this.password, this.roles).then(response => {
        
        if(response.data.message =='Usuário registrado com successo.'){
          let message = {
            status: 'success',
            text: '<strong>Novo usuário adicionado</strong>'
          }
          EventBus.$emit('showMessage', message);
          this.$router.push('/users');
        }else{
          this.errorMsg = response.data.message;
          this.dismissCountDown = '10';
        }
        return false 
      })
      .catch(e => {
        alert(e);
      });
      return false
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    
  }
}
</script>
